import maplibregl from 'maplibre-gl';

import {
  INIT_COORD_FLOOR_PLAN,
  ON_PREMISE_MAP_TILE_SERVER,
} from '../../../constant/constants';
import { ModelLayerAOA } from '../../../utils/threejs/mapbox/ModelLayerAOA';

export const add3dBuildingsLayer = (map: maplibregl.Map) => {
  if (ON_PREMISE_MAP_TILE_SERVER) {
    return;
  }
  // Insert the layer beneath any symbol layer.
  const layers = map.getStyle().layers;
  const labelLayerId =
    layers.find(
      (layer) =>
        (layer.type === 'symbol' && layer.layout?.['text-field']) ?? false,
    )?.id ?? '';

  map.addSource('openmaptiles', {
    url: `https://api.maptiler.com/tiles/v3/tiles.json?key=ePRnObKDv5frLJ3MPa3B`,
    type: 'vector',
  });

  // The 'building' layer in the Mapbox Streets
  // vector tileset contains building height data
  // from OpenStreetMap.
  map.addLayer(
    {
      id: 'add-3d-buildings',
      source: 'openmaptiles',
      'source-layer': 'building',
      type: 'fill-extrusion',
      minzoom: 15,
      filter: ['!=', ['get', 'hide_3d'], true],
      paint: {
        'fill-extrusion-color': [
          'interpolate',
          ['linear'],
          ['get', 'render_height'],
          0,
          'lightgray',
          200,
          'royalblue',
          400,
          'lightblue',
        ],
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          16,
          ['get', 'render_height'],
        ],
        'fill-extrusion-base': [
          'case',
          ['>=', ['get', 'zoom'], 16],
          ['get', 'render_min_height'],
          0,
        ],
      },
    },
    labelLayerId,
  );
};

export const addDasDevicesLayer = (map: maplibregl.Map) => {
  map.addSource('dasDevices', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    cluster: true,
    filter: ['!=', 'stop', ['get', 'status']],
  });

  map.addLayer({
    id: 'dasDevices-point',
    source: 'dasDevices',
    filter: ['!', ['has', 'point_count']],
    type: 'symbol',
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-image': [
        'concat',
        ['get', 'deviceType'],
        '-',
        ['get', 'status'],
        '-pin',
        ['get', 'liveCall'],
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 20, 1],
    },
  });

  map.addLayer({
    id: 'dasDevices-clusters',
    type: 'circle',
    source: 'dasDevices',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#51bbd6',
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
  });

  map.addLayer({
    id: 'dasDevices-cluster-count',
    type: 'symbol',
    source: 'dasDevices',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 12,
    },
  });
};

export const addDeviceImageLayer = (map: maplibregl.Map) => {
  map.addSource('deviceImage', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    cluster: false,
    filter: ['!=', 'stop', ['get', 'status']],
  });

  map.addLayer({
    id: 'circle-background',
    source: 'deviceImage',
    filter: ['!', ['has', 'point_count']],
    type: 'circle',
    paint: {
      'circle-color': '#FFFFFF',
      'circle-radius': 20, // Adjust size as needed
      'circle-opacity': 1,
      'circle-stroke-color': '#000000',
      'circle-stroke-width': 2,
    },
  });

  map.addLayer({
    id: 'deviceImage-point',
    source: 'deviceImage',
    filter: ['!', ['has', 'point_count']],
    type: 'symbol',
    layout: {
      'icon-anchor': 'center',
      'icon-allow-overlap': true,
      'icon-image': ['concat', ['get', 'dasId']],
      'icon-size': 0.1,
    },
  });
};

export const addDasDevicesNoClusterLayer = (map: maplibregl.Map) => {
  map.addSource('dasDevicesNoCluster', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },

    filter: ['!=', 'stop', ['get', 'status']],
  });

  map.addLayer({
    id: 'dasDevices-point-no-cluster',
    source: 'dasDevicesNoCluster',
    filter: ['!', ['has', 'point_count']],
    type: 'symbol',
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-image': [
        'concat',
        ['get', 'deviceType'],
        '-',
        ['get', 'status'],
        '-pin',
        ['get', 'liveCall'],
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 20, 1],
    },
  });
};

export const addDeviceTracks = (map: maplibregl.Map) => {
  map.addSource('device-tracks', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'device-tracks-layer',
    type: 'line',
    source: 'device-tracks',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': [
        'match',
        ['get', 'coordinateSource'],
        'dasbeacon',
        '#5296D5',
        'ble_aoa',
        '#5296D5',
        'uwb_aoa',
        '#5296D5',
        'uwb_basic',
        '#5296D5',
        'gps',
        '#78DC00',
        '#A1A1A1',
      ],
      'line-width': 3,
    },
  });
};

export const addDeviceTracksInFactory = (map: maplibregl.Map) => {
  map.addSource('device-tracks', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'device-tracks-layer',
    type: 'line',
    source: 'device-tracks',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#CC0000',
      'line-width': 3,
    },
  });
};

export const addAreaRoutePathLayer = (map: maplibregl.Map, before?: string) => {
  map.addSource('area-route-path', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    },
  });

  map.addLayer({
    id: 'area-route-path',
    type: 'fill',
    source: 'area-route-path',
    layout: {},
    paint: {
      'fill-color': ['get', 'pathColor'],
      'fill-opacity': 0.2,
    },
  });

  map.addLayer(
    {
      id: 'area-route-outline-layer',
      type: 'line',
      source: 'area-route-path',
      layout: {},
      paint: {
        'line-color': ['get', 'outlineColor'],
        'line-width': 2,
      },
    },
    before,
  );
};

export const addPipePathLayer = (map: maplibregl.Map, before?: string) => {
  map.addSource('pipe-path', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
  });

  map.addLayer(
    {
      id: 'pipe-path',
      type: 'line',
      source: 'pipe-path',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#00D4FF',
        'line-width': 4,
      },
    },
    before,
  );
};

export const addImageFloorPlanLayer = (map: maplibregl.Map) => {
  map.addSource('floor-plan-source', {
    type: 'image',
    url: '/static/images/img-default-floor-plan.png',
    coordinates: INIT_COORD_FLOOR_PLAN,
  });

  map.addLayer({
    id: 'floor-plan-layer',
    type: 'raster',
    source: 'floor-plan-source',
    paint: {
      'raster-fade-duration': 0,
      'raster-opacity': 1,
    },
  });
};

export const addAreasLayer = (map: maplibregl.Map, before?: string) => {
  map.addSource('area-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer(
    {
      id: 'area-layer',
      type: 'fill',
      source: 'area-source',
      paint: {
        'fill-color': ['get', 'areaColor'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.5,
          0.2,
        ],
      },
    },
    before,
  );

  map.addLayer(
    {
      id: 'area-outline-layer',
      type: 'line',
      source: 'area-source',
      layout: {},
      paint: {
        'line-color': ['get', 'outlineColor'],
        'line-width': 2,
      },
    },
    before,
  );
};

export const addRouteLayer = (map: maplibregl.Map) => {
  map.addSource('route', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
  });
  map.addLayer({
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ['get', 'pathColor'],
      'line-width': 10,
      'line-opacity': 0.5,
    },
  });

  map.addLayer({
    id: 'route-outline',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ['get', 'pathColor'],
      'line-width': 5,
      'line-opacity': 0.7,
    },
  });
};

export const addPointLocationLayer = (map: maplibregl.Map) => {
  map.addSource('point-direction', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'point-direction',
    type: 'symbol',
    source: 'point-direction',
    layout: {
      'icon-image': ['get', 'at'],
      'icon-size': 0.8,
    },
  });
};

export const getHoveredIdBuilding = (map: maplibregl.Map) => {
  map.on('mousemove', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['add-3d-buildings'],
    });
    const featureId = features[0]?.id;
    if (featureId) {
      map.setFilter('add-3d-buildings', ['all', ['!=', ['id'], featureId]]);
    }
  });
};

export const hideSpecificBuilding = (map: maplibregl.Map, ids: number[]) => {
  if (ids.length > 0) {
    const filterProp: any[] = ['all'];
    ids.forEach((id) => {
      filterProp.push(['!=', ['id'], id]);
    });
    map.setFilter(
      'add-3d-buildings',
      filterProp as maplibregl.FilterSpecification | null,
    );
  }
};

export const addImageBuilding = (
  map: maplibregl.Map,
  coordinates: [
    [number, number],
    [number, number],
    [number, number],
    [number, number],
  ],
  beforeLayer?: string,
) => {
  map.addSource('site-image', {
    type: 'image',
    url: '/static/images/japan-image.jpg',
    coordinates,
  });

  map.addLayer(
    {
      id: 'site-image-layer',
      type: 'raster',
      source: 'site-image',
    },
    beforeLayer,
  );
};

export const addImageFloorPlan = (
  map: maplibregl.Map,
  options: {
    id: string;
    url: string;
    coordinates: [
      [number, number],
      [number, number],
      [number, number],
      [number, number],
    ];
    altitude: number;
    opacity: number | null;
  },
  beforeLayer?: string,
) => {
  map.addSource(`site-image-${options.id}`, {
    type: 'image',
    url: options.url,
    coordinates: options.coordinates,
  });

  map.addLayer(
    {
      id: `site-image-layer-${options.id}`,
      type: 'raster',
      source: `site-image-${options.id}`,
      paint: {
        'raster-fade-duration': 0,
        'raster-opacity': options.opacity ? options.opacity / 100 : 1,
      },
    },
    beforeLayer,
  );
};

export const add3dAOA = (map: maplibregl.Map) => {
  map.addLayer(
    new ModelLayerAOA({
      id: 'layer-3d-aoa-1',
      origin: [121.51790569609602, 25.064448802818905],
      altitude: 0,
      rotateY: 0,
      color: 0x000080,
    }),
  );
};

export const addCenterPoint = (
  map: maplibregl.Map,
  center: [number, number],
) => {
  map.addSource(`project-center`, {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: center,
      },
    },
  });

  map.addLayer({
    id: 'layer-project-center',
    source: 'project-center',
    type: 'circle',
    paint: {
      'circle-color': 'red',
      'circle-radius': 2,
    },
  });
};

export const addHeatMap = (map: maplibregl.Map) => {
  map.addSource('all-workers-track-path', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'track-path-heat',
    type: 'heatmap',
    source: 'all-workers-track-path',
    paint: {
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 3, 15, 0.5],
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(0, 255, 255, 0)',
        0.1,
        'rgba(0, 255, 255, 0.3)',
        0.2458,
        '#00ff00',
        0.5052,
        '#ffff00',
        0.7656,
        '#f5940a',
        1,
        '#ff0000',
      ],
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 12, 20],
    },
  });
};

export const addTowerCraneLayer = (map: maplibregl.Map) => {
  map.addSource('dascas', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'dascas-border-layer',
    type: 'symbol',
    source: 'dascas',
    layout: {
      'icon-anchor': 'center',
      'icon-image': 'dascas-border',
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 20, 1],
      'icon-allow-overlap': true,
    },
    paint: {
      'icon-color': ['get', 'borderColor'],
    },
  });

  map.addLayer({
    id: 'dascas-layer',
    type: 'symbol',
    source: 'dascas',
    layout: {
      'icon-anchor': 'center',
      'icon-image': 'dascas-pin',
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 20, 1],
      'icon-allow-overlap': true,
    },
  });

  map.addSource('dascas-g', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    filter: ['!=', 'stop', ['get', 'status']],
  });

  map.addLayer({
    id: 'dascas-g-border-layer',
    type: 'symbol',
    source: 'dascas-g',
    layout: {
      'icon-anchor': 'center',
      'icon-image': 'dascas_g-border',
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 20, 1],
      'icon-allow-overlap': true,
    },
    paint: {
      'icon-color': ['get', 'borderColor'],
    },
  });

  map.addLayer({
    id: 'dascas-g-layer',
    type: 'symbol',
    source: 'dascas-g',
    layout: {
      'icon-anchor': 'center',
      'icon-image': [
        'concat',
        ['get', 'deviceType'],
        '-',
        ['get', 'status'],
        '-pin',
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 20, 1],
      'icon-allow-overlap': true,
    },
  });
};
